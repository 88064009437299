
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// CHANGE THESE FOR CUSTOM COLOR PALLETTE
$job-board-primary: mat.define-palette(mat.$teal-palette);
$job-board-accent: mat.define-palette(mat.$light-green-palette);

// The warn palette is optional (defaults to red).
//$apollo-whitsundays-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$job-board-theme: mat.define-light-theme((
  color: (
    primary: $job-board-primary,
    accent: $job-board-accent,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($job-board-theme);

/* DEFINE SCSS COLOR VARIABLES */
$primary: mat.get-color-from-palette($job-board-primary, 800);
$accent: mat.get-color-from-palette($job-board-accent, 900);
