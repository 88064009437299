@import "theme.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

button[disabled] {
  background-color: #e5e5e54a !important;
  color: #C2C2C2 !important;
}

.mdc-notched-outline__notch {
  border-right: none !important;
}


html, body {
  height: 100%;
  font-size: 19px;
}

body {
  color: $primary;
  margin: 0;
  font-family: 'Quicksand', "Helvetica Neue", sans-serif;
}

button, label {
  font-family: 'Quicksand', "Helvetica Neue", sans-serif;
}

p {
  margin: 15px 0;
  line-height: 1.6rem;
}

a {
  cursor: pointer;
}

.text-link {
  position: relative;
  display: inline-block;
  width: fit-content;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 50%;
    background-color: #88dba8;
  }

  &:hover::before {
    width: 100%;
    left: 0;
  }
}

ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}

li {
  padding-left: 1em;
  text-indent: -1.6em;
}

li::before {
  vertical-align: sub;
  font-family: "Material Icons";
  content: "\e409";
  font-size: 1.3rem;
  color: #999;
}

.xng-breadcrumb-list {
  ul, li {
    padding: inherit;
    vertical-align: inherit;
    content: inherit;
    font-size: inherit;
    text-indent: inherit;

  }

  li {
    text-indent: inherit;

    &::before {
      content: inherit;
    }
  }

  li {
    font-size: 0.8rem;
  }

  mat-icon {
    font-size: 0.9rem;
    width: 0.9rem;
    height: 0.9rem;
  }
}

/* HEADER STYLES */

h1 {
  font-size: 2.3rem;
  line-height: 2.9rem;
  font-weight: 700;
  color: #00555A;
}

h2 {
  font-size: 2.0rem;
  line-height: 2.6rem;
  font-weight: 600;

  color: #00555A;
}

h3 {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  color: #00555A;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
  color: #00555A;
}

/* CONTAINER */

.article-container {


}

.navigation-container-space {
  max-width: 1280px;
  //padding: 0 5rem;
  @media (max-width: 1280px) {
    padding: 0 0.2rem;
  }
  margin: 0 auto;
}

.container-space {
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;

  h1 {
    text-align: center;
  }
}

.container-padding {
  padding: 0 20px;
}

section {
  margin-top: 4rem;
}

/* GLOBAL STYLES */

details > summary {
  list-style: none;
}

details > summary::-webkit-details-marker {
  display: none;
}

.backpacker-profile-image {
  margin-bottom: 10px;
  width: 110px;
  height: 110px;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-image-smaller {
  width: 80px;
  height: 80px;
}


.notificationSnack {
  background: #009688;


  //right: 0 !important;
  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    padding: 1rem 1.5rem !important;
    font-weight: 600;
  }

  .mdc-snackbar__surface {
    background-color: #00555A !important;
  }

}

.articleContent {
  img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 3 / 2;
  }

  h1 {
    text-align: center !important;
  }

  h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 2rem;
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  h3 {
    font-weight: 600;
    margin-top: 30px;
    font-size: 1.5rem;

    a {
      font-size: inherit;
    }
  }

  h4 {
    font-weight: 600;
    margin-top: 20px;
    font-size: 1.2rem;
  }

  a {
    font-weight: 500;
  }

  ul {
    margin-left: 30px;

    li {
      margin: 10px 0;
    }
  }


  blockquote {
    border: 3px solid #88dba899;
    padding: 15px 15px;
    border-radius: 10px;
    margin: 20px 0;
  }

  iframe {
    width: 80%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  p {
    font-size: 1.05rem;
    line-height: 1.8rem;
  }

  li, a, blockquote {
    font-size: 1.05rem;
  }

  a {
    color: #6F59E7;
  }

  .prod-image {
    height: 350px;
    width: auto;
    margin: 25px auto 0;
  }
}


.profileListImageContainer {
  flex-shrink: 0;
  width: 100px;
  height: 100px;

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.profileViewImageContainer {
  width: 200px;
  height: 200px;

  img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}


.notificationSnack {
  background: #009688;


  //right: 0 !important;
  .mat-mdc-snack-bar-label.mdc-snackbar__label {
    padding: 1rem 1.5rem !important;
    font-weight: 600;
  }

  .mdc-snackbar__surface {
    background-color: #00555A !important;
  }

}

.mat-icon {
  overflow: visible !important;
}

.spin-icon {
  transform: rotate(180deg);
}

.apply-now-dialog {
  width: 99%;
  height: 98%;
  max-width: 100% !important;

  .mat-mdc-dialog-content {
    max-height: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .apply-now-dialog {
    width: 1280px;
    max-width: 80vw !important;
    height: auto;

    .mat-mdc-dialog-content {
      max-height: 75vh !important;
    }
  }
}

.verified, .unverified {
  position: relative;

  mat-icon {
    position: absolute;
    top: -1px;
    right: -24px;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: #6F59E7;
  }
}

.unverified {
  h4 {
    color: #FF0000;
  }

  mat-icon {
    color: #FF0000;
  }
}


.unverifiedErrorMsg {
  border-radius: 5px;
  padding: 10px 10px;
  background-color: #FEE3E2;
  border-left: 5px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00555a;
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 5px;

  mat-icon {
    margin-right: 20px;
  }
}


.smallNow {
  font-size: 0.8rem;
}

.websiteLogo {
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div {
    font-size: 0.65rem;
    margin-top: -8px;
    margin-bottom: -8px;
    font-weight: 500;
    letter-spacing: 3px;
  }

  //mobile media
  @media (min-width: 900px) {
    font-size: 1.2rem;
  }

}


// this is to overwrite the p tags inside a innerHTML job-card etc.
.job-card, .applicantContainer {
  p {
    line-height: 1.3rem;
  }
}


.manageBtn, .inverseManageBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #F3F0FE !important;
  color: #6F59E7 !important;;
  border-radius: 10px;
  font-weight: 600;
  font-size: 0.65rem;
  text-align: center;
  @media (min-width: 500px) {
    font-size: 0.75rem;
  }
  @media (min-width: 767px) {
    font-size: 0.85rem;
  }

  mat-icon {
    font-size: 0.85rem;
    width: 0.85rem;
    height: 0.85rem;
    margin-right: 10px;
    @media (min-width: 767px) {
      font-size: 1rem;
      width: 1rem;
      height: 1rem;
    }
  }

  &:hover {
    transition: all 0.1s linear;
    background-color: #88DBA8 !important;;
    color: white !important;;
  }
}

.inverseManageBtn {
  background-color: #88DBA8 !important;;
  color: #fff !important;;

  &:hover {
    transition: all 0.1s linear;
    background-color: #F3F0FE;
    color: #6F59E7;
  }
}

.lightBtn {
  background-color: #F3F0FE !important;
  color: #6F59E7 !important;
}

.primaryBtn {
  background-color: #009688 !important;
  color: white !important;
}

.generalBtn {
  min-width: 10rem;
  font-size: 0.85rem;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  border-radius: 2rem;

  @media (min-width: 767px) {
    font-size: 1.05rem;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
  }
}

.mdc-dialog__title {
  font-family: 'Quicksand', "Helvetica Neue", sans-serif !important;
  font-weight: 700 !important;
}

.dot-spacer {
  height: 6px !important;
  width: 6px !important;
  font-size: 6px !important;
  margin: 0 10px !important;
  color: #00555a;
}

.underline-dec {
  border-bottom: 4px solid #88dba8;
}

.underline-dec-blue {
  border-bottom: 1px solid #6F59E7;
}

.border-dec {
  border-radius: 8px;
  border: 2px solid #88dba8;
  padding: 3px 10px;
}

.call-to-action-btn {
  margin-top: 40px;
  font-size: 1.2rem;
  padding: 28px 20px !important;
}

.section-divider {
  padding-bottom: 28px;
  padding-top: 28px;
  border-bottom: 2px solid #e6e6e6;
  color: #00555A;
}


.iconCircle-sm {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 1.5rem;
  min-height: 1.5rem;

  fa-icon {
    font-size: 0.7rem;
    color: white;
  }
}


.iconCircle-lg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;

  fa-icon {
    font-size: 0.8rem;
    color: white;
  }
}

.headerTextLabel {
  width: 50%;
  margin-bottom: 25px;

  h4 {
    opacity: 0.4;
  }

  p {
    font-size: 1.1rem;
  }
}

.coverLetterDiv {
  width: 100%;
}

.fileUploadContainer {
  .success-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: green;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    z-index: 200;
  }

  .resume-dropzone {
    position: relative;
  }

  .success-overlay mat-icon {
    transform: scale(1.2);
    margin-bottom: 16px;
  }
}


.pricingModels {
  margin: 2rem 0 1rem 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  .priceCardContainer {
    width: 100%;
    border-radius: 15px;
    padding: 50px 37px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 500px;
    position: relative;
    margin-bottom: 20px;

    @media only screen and (max-width: 767px) {
      padding: 50px 15px;
    }

    @media only screen and (min-width: 767px) {
      width: 48.5%;
    }

    @media only screen and (min-width: 1100px) {
      width: 31.5%;
    }

    .recommendedBanner, .currentAdTypeBanner {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background: $primary;
      color: white;
      padding: 5px 0px;
      z-index: 1;
      width: 100%;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px
    }

    p {
      height: 120px;
      @media only screen and (max-width: 767px) {
        height: 150px;
      }
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
    }

    ul {
      margin-top: 20px;
      text-align: left;

      li {
        font-weight: 400;

        &::before {
          margin-right: 15px;
        }
      }
    }
  }

  &.two-tiers .priceCardContainer {
    @media only screen and (min-width: 767px) {
      width: 46%;
    }
  }

  .recommendedCard {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }

  .disabledCard {
    pointer-events: none;
    opacity: 0.7;
  }

  .icon-list {
    text-align: left;
    height: 40px !important;
    font-weight: 500;
    padding-right: 0 !important;
  }

  .smallHandwriting {
    font-family: 'Caveat', "Quicksand", sans-serif;
    font-size: 22px;
    color: rgba(0, 85, 90, 0.53);
  }
}

.locked-content {
  padding: 10px 15px;
  border-radius: 15px;
  background-color: #00695ce3;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-weight: 600;

  mat-icon {
    font-size: 28px;
    width: 28px;
    height: 28px;
    margin-bottom: 4px;
  }
}

.errorMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  //padding: 10px 10px;
  background-color: #FEE3E2;
  border-left: 5px solid red;
  color: red;
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 5px;

  mat-icon {
    margin-right: 7px;
  }
}

.infoMsg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  border: #009688 2px solid;
  font-size: 15px;
  font-weight: 500;
  padding: 45px 20px 10px 20px;
  position: relative;

  p {
    margin: 10px 0 0 0;
    font-size: 0.85rem;
  }

  h4 {
    position: absolute;
    top: 0;
    padding: 7px 0;
    background-color: #009688;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 1rem;
    font-weight: 500;
  }

  mat-icon {
    margin-right: 7px;
  }

  .icon-list {
    height: 30px !important;

    .mdc-list-item__primary-text {
      color: $primary !important;
      font-size: 15px !important;
    }
  }
}

.disableBtn {
  pointer-events: none;
  background-color: #e5e5e54a !important;
  color: #C2C2C2 !important;
}

::ng-deep .swiper-button-next {
  color: red !important;
  background-image: url("assets/arrow-next.svg") !important;
}


/* MATERIAL OVERRIDES */

//.mat-horizontal-content-container {
//  padding: 0 !important;
//}
.cancellation-stepper {
  .mat-horizontal-stepper-header-container {
    display: none;
  }
}


ngx-dropzone-image-preview {
  img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover !important;
  }
}

.mat-stepper-vertical, .mat-stepper-horizontal, .mat-mdc-dialog-content {
  font-family: 'Quicksand', "Helvetica Neue", sans-serif !important;
}

@media only screen and (max-width: 600px) {
  .employee-signup-form {
    .mat-horizontal-stepper-wrapper {
      margin-top: 20px;
    }

    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }
}

.mdc-list-item__primary-text {
  color: $primary !important;
  font-size: 18px !important;
}

@media only screen and (max-width: 767px) {
  .mdc-list-item__primary-text {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 400px) {
  .mdc-list-item__primary-text {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 350px) {
  .mdc-list-item__primary-text {
    font-size: 12px !important;
  }
}

.mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin: 0 !important;
  margin-right: 8px !important;
}

.mat-mdc-radio-button {
  label {
    font-weight: 500;
    font-size: 0.9rem;
  }
}

.mat-badge-content {
  background: #88DBA8;
  color: #00555a;
}

.ribbon {
  width: 100px; /* Reduced from 150px to 100px */
  height: 100px; /* Reduced from 150px to 100px */
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 3px solid #2980b9; /* Reduced border width for a smaller ribbon */
}

.ribbon span {
  position: absolute;
  display: block;
  width: 150px; /* Adjusted width for smaller size */
  padding: 10px 0; /* Adjusted padding for a smaller ribbon */
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 700 14px/1 'Lato', sans-serif; /* Adjusted font size */
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

/* top right*/
.ribbon-top-right {
  top: -7px; /* Adjusted for smaller size */
  right: -7px; /* Adjusted for smaller size */
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -17px; /* Adjusted for smaller size */
  top: 20px; /* Adjusted for smaller size */
  transform: rotate(45deg); /* Kept the same, as it's the rotation */
}

.handwriting {
  font-family: 'Caveat', "Quicksand", sans-serif;
  color: $primary;
}

.hint {
  margin-top: 2px;
  font-size: 0.8rem;
  color: #a0a0a0;
}

.tooltip-icon {
  font-size: 0.9rem;
  color: #84d3a2;
  cursor: pointer;
}

.tooltip-md {
  .mdc-tooltip__surface {
    font-size: 0.8rem !important;
    line-height: 1rem !important;
    padding: 10px !important;
    max-width: 350px !important;
    border-radius: 10px !important;
  }
}

.tooltip-sm {
  .mdc-tooltip__surface {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
    padding: 10px !important;
    max-width: 350px !important;
    border-radius: 10px !important;
  }
}

.star-rating {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;

  .star {
    cursor: pointer;
    font-size: 40px;
    width: 40px;
    height: 40px;
    color: #F0CF4D;
  }
}
