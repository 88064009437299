// Larger screens: tablets and small laptops
@media only screen and (min-width: 768px) {
  section{
    margin-top: 6rem;
  }

}

// Even larger screens: large laptops and desktops
@media only screen and (min-width: 1024px) {
}
